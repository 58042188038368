import appAxios from '@/utils/appAxios';


export async function deleteDataWithUrl(url) {
    try {
        const response = await appAxios.delete(url);
        if (response.status === 204) {
            return {
                isSuccess: true,
                data: response.data,
                message: '',
            };
        } else {
            return {
                isSuccess: false,
                data: '',
                message: ' Silinemedi',
            };
        }
    } catch (e) {
        return {
            isSuccess: false,
            data: {},
            message: (e.response.data.errors != null) ? e.response.data.errors.ValidationException[0] : ' Silinirken Hata oluştu',
        };
    }
}

export async function deleteDataById(url, id, resourceName) {
    try {
        const response = await appAxios.delete(url + '/' + id);
        if (response.status === 204) {
            return {
                isSuccess: true,
                data: response.data,
                message: '',
            };
        } else {
            return {
                isSuccess: false,
                data: '',
                message: resourceName + ' Silinemedi',
            };
        }
    } catch (e) {
        return {
            isSuccess: false,
            data: {},
            message: (e.response.data.errors != null) ? e.response.data.errors.ValidationException[0] : resourceName + ' Silinirken Hata oluştu',
        };
    }
}

export async function patchData(url, id, patchDocument, resourceName) {
    return await patchRawData(url + '/' + id, patchDocument, resourceName);
}

export async function patchRawData(url, patchDocument, resourceName) {
    const response = await appAxios.patch(url, patchDocument);
    if (response.status === 204) {
        return {
            isSuccess: true,
            data: '',
            message: resourceName + ' Bilgileri Başarı İle Güncellendi',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: resourceName + ' Güncelleme İşlemi Başarısız',
        };
    }
}

export async function insertData(url, data, resourceName) {
    try {
        const response = await appAxios.post(url, data);
        if (response.status === 201) {
            return {
                isSuccess: true,
                data: response.data.id,
                message: resourceName + ' başarı ile eklendi',
            };
        } else {

            return {
                isSuccess: false,
                data: {},
                message: response.data,
            };
        }
    } catch (e) {
        return {
            isSuccess: false,
            data: {},
            message: (e.response.data.errors != null) ? e.response.data.errors.ValidationException[0] : resourceName + ' Eklenirken Hata oluştu',
        };
    }
}

export async function insertDataReturnEntity(url, data, resourceName) {
    try {
        const response = await appAxios.post(url, data);
        if (response.status === 201) {
            return {
                isSuccess: true,
                data: response.data,
                message: resourceName + ' başarı ile eklendi',
            };
        } else {
            return {
                isSuccess: false,
                data: {},
                message: response.data,
            };
        }
    } catch (e) {
        return {
            isSuccess: false,
            data: {},
            message: resourceName + ' Eklenirken Hata oluştu',
        };
    }
}

export async function getDataBydId(url, id, resourceName) {
    const response = await appAxios.get(url + '/' + id);
    if (response.status === 200) {
        return {
            isSuccess: true,
            data: response.data,
            message: '',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: resourceName + ' Bulunamadı',
        };
    }
}

export async function getPaginatedData(url, resourceName) {
    const response = await appAxios.get(url);
    if (response.status === 200) {
        return {
            isSuccess: true,
            data: response.data.data,
            message: '',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: resourceName + ' Bulunamadı',
        };
    }
}

export async function getListData(url, resourceName) {
    const response = await appAxios.get(url);
    if (response.status === 200) {
        return {
            isSuccess: true,
            data: response.data,
            message: '',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: resourceName + ' Bulunamadı',
        };
    }
}

export async function getData(url) {
    const response = await appAxios.get(url);
    if (response.status === 200) {
        return {
            isSuccess: true,
            data: response.data,
            message: '',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: 'Hata oluştu',
        };
    }
}

export async function getSingleData(url) {
    const response = await appAxios.get(url);
    if (response.status === 200) {
        return {
            isSuccess: true,
            data: response.data.data[0],
            message: '',
        };
    } else {
        return {
            isSuccess: false,
            data: '',
            message: 'Hata oluştu',
        };
    }
}

export async function getExcel(url) {
    const response = await appAxios.get(url, {
        responseType: 'arraybuffer',
    });
    return response;
}

export class getResponseStatus {
}